/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { linkWallet, NxWeb3 } from "../../libs/NxWeb3";
import "./index.scss";

const Home = (): JSX.Element => {
  const [address, setAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const hideAddress = (str: string) => {

    return str.substr(0, 5) + "****" + str.substr(str.length - 4);
  }


  const connectMetaMask = async () => {
    const result = await linkWallet(() => {
      setAddress(NxWeb3.instance.accountAddress);
    })

    setIsConnected(result);
    if (NxWeb3.instance.isInited) {
      setAddress(NxWeb3.instance.accountAddress);
    }
  }

  return (
    <>
      <div className="home">
        <div className="header w-full px-1 md:px-5 py-1 sticky top-0">
          <div className="flex items-center justify-between">
            <div className="logo flex items-center justify-center">
              <img src={require('../../assets/img/logo.png').default} alt="" className=" w-90 md:w-70" />
              <p className="flex items-center justify-center text-head md:text-2xl top-1 ml-1 relative">
                <span className="font-BBH text-blue line-height">Twitter</span>
                <span className="font-BBH text-gold line-height">Doge</span>
              </p>
            </div>
            <div className="flex animate-fade-in flex-row flex items-center justify-end">
              <ul className="text-center mt-3 flex mt-0 text-sm items-stretch h-full hidden md:flex">
                <li className=" flex self-stretch px-1">
                  <a href="/#tokenomics">
                    <div className=" relative cursor-pointer">
                      <span className="inline-block flex ">
                        Tokenomics
                      </span>
                    </div>
                  </a>

                </li>
                <li className=" flex self-stretch px-1">
                  <a href="/#NFT">
                    <div className=" relative cursor-pointer">
                      <span className="inline-block flex ">
                        NFT
                      </span>
                    </div>
                  </a>
                </li>
                <li className=" flex self-stretch px-1">
                  <a href="/#roadmap">
                    <div className=" relative cursor-pointer">
                      <span className="inline-block flex ">
                        Roadmap
                      </span>
                    </div>
                  </a>

                </li>
                <li className=" flex self-stretch px-1">
                  <a href="/#Community">
                    <div className=" relative cursor-pointer">
                      <span className="inline-block flex ">
                        Community
                      </span>
                    </div>
                  </a>
                </li>
                <li className=" flex self-stretch px-1">
                  <a href="/gamefi">
                    <div className=" relative cursor-pointer">
                      <span className="inline-block flex ">
                        Gamefi
                      </span>
                    </div>
                  </a>
                </li>
                <li className=" flex self-stretch px-1">
                  <a href="/NFTstake">
                    <div className=" relative cursor-pointer">
                      <span className="inline-block flex ">
                        NFT stake
                      </span>
                    </div>
                  </a>
                </li>

              </ul>
              <div className="block mx-auto text-center">
                <button
                  className="cta-btn-transparent mobile-menu w-inline-block md:py-1"
                  onClick={() => {
                    connectMetaMask()
                  }}
                >

                  <div className="cta-btn__txt">{address !== '' ? hideAddress(address) : "Connect Wallet"}</div>
                  <div className="cta-btn-transparent__gradhide"></div>
                  <div className="cta-btn-transparent__gradhide-red"></div>
                  <div className="cta-btn-transparent__animbox"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="container ">
            <div className="wrap px-2 md:px-5 flex">
              <div className="left">
                <h1 className="text-4xl md:text-3xl font-BBM md:text-left md:w-full w-80% ">$TDoge is a Decentralised MemeToken</h1>
                <p className="text-white/80 text-2xl  md:text-sm des md:w-full w-60%">in the future will be release NFT at the sametime will also establish NFTs Based P2E game, SocialFi and DeFi functional explanation.</p>
                <div className="btn-wrap flex">
                  <a href="https://www.tdogebsc.com/" target="_blank" className="cta-btn-transparent mobile-menu w-inline-block  justify-center">
                    <img src={require('../../assets/img/swap-icon.svg').default} loading="eager" alt="" className="cta-btn--ico mobile-menu" />
                    <div className="cta-btn__txt lh-70"> Tdog Swap</div>
                    <div className="cta-btn-transparent__gradhide-red"></div>
                    <div className="cta-btn-transparent__animbox"></div>
                  </a>
                  <a href="https://drive.google.com/file/d/13uK0pTOz3W4zGS6gdKJrsLY1FkAr4u4C/view" target="_blank" className="cta-btn-transparent mobile-menu w-inline-block  justify-center ">
                    <div className="cta-btn__txt lh-70"> Whitepaper</div>
                    <div className="cta-btn-transparent__gradhide"></div>
                    <div className="cta-btn-transparent__gradhide-red"></div>
                    <div className="cta-btn-transparent__animbox"></div>
                  </a>
                </div>
              </div>
              <div className="right hidden md:block">
                <div className="img">
                  <img src={require('../../assets/img/banner_dog.png').default} alt="" width={'100%'} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tokenomics pt-4" id="tokenomics">
          <div className="title">
            <span>
              TOKENOMICS
            </span>
          </div>
          <div className="wrap px-5 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <img src={require('../../assets/img/piechart.png').default} alt="" width={'100%'} />
              <div className="info">
                <div className="item">
                  <h6 className="tit">
                    Total Supply:
                  </h6>
                  <p className="num">10,0000000000</p>
                </div>
                <div className="item">
                  <h6 className="tit">
                    <span>Buy Tax</span> : <span className="font-AVHL">7%</span>
                  </h6>
                  <ul>
                    <li>
                      <p>1% Goes to Liquidity</p>
                    </li>
                    <li>
                      <p>3% Goes to Marketing</p>
                    </li>
                    <li>
                      <p>3% Reflection to Holders in Dogecoin</p>
                    </li>
                  </ul>
                </div>
                <div className="item">
                  <h6 className="tit">
                    <span>Sell Tax</span> : <span className="font-AVHL">7%</span>
                  </h6>
                  <ul>
                    <li>
                      <p>1% Goes to Liquidity</p>
                    </li>
                    <li>
                      <p>3% Goes to Marketing</p>
                    </li>
                    <li>
                      <p>3% Reflection to Holders in Dogecoin</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NFT pt-4" id="NFT">
          <div className="container">
            <div className="title">
              <span>
                NFT
              </span>
            </div>
            <div className="imgBOx">
              <div className="item item1">
                <div className="text-left version flex flex-col md:flex-row">
                  <p className="flex md:ml-0 ml-1 mb-1 md:mb-0"><span className="bg-gold inline-block">VET 1.0</span></p>

                  <i className="text-sm ml-1 font-normal font-AVB text-uppercase">mint time：12.15</i>
                </div>
                <img className="pic hidden md:block" src={require('../../assets/img/dog_v1.png').default} alt="" />
                <img className="pic block md:hidden" src={require('../../assets/mobile/dog_v1.png').default} alt="" />
                <div className="btn-wrap hidden md:block">
                  <a href="/mint" target="_blank" className="cta-btn-transparent mobile-menu w-inline-block mw-[330px]  justify-center px-0 py-0 text-xl m-auto">
                    <div className="cta-btn__txt lh-70 mr-1"> TDoge NFT</div>
                    <div className="cta-btn-transparent__gradhide"></div>
                    <div className="cta-btn-transparent__gradhide-red"></div>
                    <div className="cta-btn-transparent__animbox"></div>
                    <img src={require('../../assets/img/arrow.svg').default} loading="eager" alt="" className="cta-btn--ico mobile-menu w-[42px] realtive top-02" />
                  </a>
                </div>
              </div>
              <div className="item item2">

                <div className="text-center version flex hidden md:flex">
                  <span className="bg-blue">VET 2.0</span>
                  <i className="text-sm ml-1 font-normal font-AVB text-blue text-uppercase">coming soon</i>
                </div>
                <div className="flex flex-col">
                  <img className="pic" src={require('../../assets/img/dog_v2.png').default} alt="" />
                  <img className="pic" src={require('../../assets/img/dog_v2_2.png').default} alt="" />
                </div>
                <div className="text-left version flex flex-col md:flex-row block md:hidden">
                  <p className="flex md:ml-0 ml-1 mb-1 mt-1 md:mb-0"><span className="bg-blue inline-block">VET 2.0</span></p>

                  <i className="text-sm ml-1 font-normal font-AVB text-blue text-uppercase">coming soon</i>
                </div>
              </div>
            </div>
            <div className="btn-wrap block md:hidden">
              <a href="#" target="_blank" className="cta-btn-transparent mobile-menu w-inline-block  justify-center m-auto">
                <div className="cta-btn__txt lh-70 mr-1"> TDoge NFT</div>
                <div className="cta-btn-transparent__gradhide"></div>
                <div className="cta-btn-transparent__gradhide-red"></div>
                <div className="cta-btn-transparent__animbox"></div>
                <img src={require('../../assets/img/arrow.svg').default} loading="eager" alt="" className="cta-btn--ico mobile-menu w-[42px] realtive top-02" />
              </a>
            </div>
          </div>
        </div>
        <div className="team pt-4">
          <div className="title">
            <span>
              TEAM
            </span>
          </div>
          <div className="wrap px-2 md:px-5">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 timeList">

              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team1.png').default} alt="" />
                <h4 className="name font-AVB">Kevin Sorbo</h4>
              </div>
              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team2.png').default} alt="" />
                <h4 className="name font-AVB">John Collison</h4>
              </div>
              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team3.png').default} alt="" />
                <h4 className="name font-AVB">Gavin Wood</h4>
              </div>
              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team4.png').default} alt="" />
                <h4 className="name font-AVB">Partrick Collison</h4>
              </div>
              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team5.png').default} alt="" />
                <h4 className="name font-AVB">John Schulman</h4>
              </div>
              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team6.png').default} alt="" />
                <h4 className="name font-AVB">Vitaliy Klychko</h4>
              </div>
              <div className="item flex flex-col items-center justify-center">
                <img src={require('../../assets/img/team7.png').default} alt="" />
                <h4 className="name font-AVB">Dan Bongino</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap pt-4 mt-5" id="roadmap">
          <div className="title">
            <span>
              ROADMAP
            </span>
          </div>
          <div className="wrap px-5">
            <div className="grid grid-cols-1 lg:grid-cols-3 list">
              <div className="item flex flex-col items-start justify-start">
                <div className="version"><span>VET 1.0</span></div>
                <div className="date"><span className="text-blue">PHASE</span> <span className="text-blue font-AVB">01</span> <span className="font-AVB">2022.11</span></div>
                <ul>
                  <li><p>Twitter official follow cooperation</p></li>
                  <li><p>Officially established project</p></li>
                  <li><p>Official website construction</p></li>
                  <li><p>Telegram Global Construction</p></li>
                  <li><p>Twitter construction and operation</p></li>
                  <li><p>Audit Report</p></li>
                  <li><p>KYC</p></li>
                  <li><p>Whitelist race</p></li>
                  <li><p>Marketing activities</p></li>
                  <li><p>Pre-sale starts</p></li>
                  <li><p>Official launch</p></li>
                </ul>
              </div>
              <div className="item flex flex-col items-start justify-start">
                <div className="version"><span>VET 1.0</span></div>
                <div className="date"><span className="text-blue">PHASE</span> <span className="text-blue font-AVB">02</span> <span className="font-AVB">2022.12</span></div>
                <ul>
                  <li><p>Swap online</p></li>
                  <li><p>New official website online</p></li>
                  <li><p>NFT online</p></li>
                  <li><p> Ecological Governance Token Development</p></li>
                  <li><p>Audit Report</p></li>
                  <li><p>Pre-sale starts</p></li>
                  <li><p>NFT Farm stake</p></li>
                  <li><p>Marketing</p></li>
                  <li><p>Listing of CG CMC</p></li>
                  <li><p>Game blind box is online</p></li>
                </ul>
              </div>
              <div className="item flex flex-col items-start justify-start">
                <div className="version"><span>VET 1.0</span></div>
                <div className="date"><span className="text-blue">PHASE</span> <span className="text-blue font-AVB">03</span> <span className="font-AVB">2023.01</span></div>
                <ul>
                  <li><p>Lower Token Fees</p></li>
                  <li><p>Game Beta</p></li>
                  <li><p>Game online</p></li>
                  <li><p>Bridge to ETH </p></li>
                  <li><p>Exchange list</p></li>
                  <li><p>Tweet interactive tipping</p></li>
                  <li><p>Develop Dogmetaverse</p></li>
                  <li><p>Dogmetaverse online</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="Community pt-4 mt-5" id="Community">
          <div className="title">
            <span>
              Community
            </span>
          </div>
          <div className="wrap px-2 md:px-5 flex flex-col md:flex-row items-center justify-center">
            <div className="left">
              <img src={require('../../assets/img/Community.png').default} alt="" width={'100%'} />
            </div>
            <div className="right">
              <p>TwitterDoge A Community Driven <br />MemeToken, With $TDOGE users can earn <br />passive income in Doge coin, also they can <br />interact with our verious Ecosystem.</p>
              <div className="socialgroup flex items-center w-full justify-between">
                <div className="text text-blue font-AVB"> join Community</div>
                <div className="btn_group flex items-center">
                  <a href=" https://t.me/TwitterDogeOfficialclub" target={'_blank'}>
                    <img src={require('../../assets/img/tel.png').default} alt="" width={'100%'} />
                  </a>
                  <a href="https://twitter.com/TDOGEtoken" target={'_blank'}>
                    <img src={require('../../assets/img/twitter.png').default} alt="" width={'100%'} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="backers pt-4 mt-5 px-2 md:px-5" id="backers">
          <div className="title">
            <span>
              backers&partners
            </span>
          </div>
          <div className="wrap ">
            <div className="grid grid-cols-2 md:grid-cols-3 backersList">
              {
                Array.from(Array(6), (v, index) => {
                  return (<>
                    <div className="item flex flex-col items-center justify-center">
                      <img src={require(`../../assets/img/backers${index + 1}.png`).default} alt="" width={'100%'} />
                    </div>
                  </>)
                })
              }


            </div>
          </div>
        </div>
        <div className="footer">
          <p className="text-center py-1 bg-black text-sm">TwitterDoge @2022 | Alright Reserved</p>
        </div>
      </div>
    </>
  );
}

export default Home;
