/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import CountDown from '../../components/countDown';
// import { toast } from 'react-toastify';
import './index.scss'




const PreMint = (): JSX.Element => {
  // const [address, setAddress] = useState('');

  // const hideAddress = (str: string) => {

  //   return str.substr(0, 5) + "****" + str.substr(str.length - 4);
  // }

  useEffect(() => {
  }, [])





  // const [open, setOpen] = useState(false);


  return (
    <>
      <div className="premint">
        <div className="header w-full px-1 md:px-5 py-sm md:py-1 sticky top-0">
          <div className="flex items-center justify-end md:justify-between">
            <a href='/' className="logo flex items-center justify-center hidden md:flex">
              <img src={require('../../assets/img/logo.png').default} alt="" className=" w-90 md:w-70" />
              <p className="flex items-center justify-center text-head md:text-2xl top-1 ml-1 relative">
                <span className="font-BBH text-blue line-height">Twitter</span>
                <span className="font-BBH text-gold line-height">Doge-NFT</span>
              </p>
            </a>
            <div className="flex animate-fade-in flex-row flex items-center justify-end">
              <div className="block mx-auto text-center">
                <a href="#" target="_blank" className="cta-btn-transparent mobile-menu w-inline-block md:py-1 relitive">
                  <div className="cta-btn__txt">Connect Wallet</div>
                  <div className="cta-btn-transparent__gradhide"></div>
                  <div className="cta-btn-transparent__gradhide-red"></div>
                  <div className="cta-btn-transparent__animbox"></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="logo flex items-center justify-center flrx md:hidden">
            <img src={require('../../assets/img/logo.png').default} alt="" className=" w-90 md:w-70" />
            <p className="flex items-center justify-center text-head md:text-2xl top-1 ml-1 relative">
              <span className="font-BBH text-blue line-height">Twitter</span>
              <span className="font-BBH text-gold line-height">Doge-NFT</span>
            </p>
          </div>
          <h1 className="title">TwitterDoge NFT</h1>
          <p className="des">Mints Starts in</p>
          <div className="time">
            <CountDown propsDate={'1671021000000'} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PreMint;