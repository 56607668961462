import React, { useEffect, useState } from "react";
import './index.scss'

function CountDown(props: any) {
    const [Day, setDay] = useState<any>(0)
    const [Hour, setHour] = useState<any>(0)
    const [Minute, setMinute] = useState<any>(0)
    const [Second, setSecond] = useState<any>(0)
    const { propsDate } = props
    // console.log(props)
    const countTime = () => {
        //获取当前时间
        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        // var endDate = new Date(propsDate);
        var end = propsDate;
        //时间差
        var leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间
        if (leftTime >= 0) {
            setDay(() => {
                const val = Math.floor(leftTime / (24 * 3600 * 1000))
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
            setHour(() => {
                const val = Math.floor(leftTime % (24 * 3600 * 1000) / (3600 * 1000))
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
            setMinute(() => {
                const val = Math.floor(leftTime / 1000 / 60 % 60)
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
            setSecond(() => {
                const val = Math.floor(leftTime / 1000 % 60)
                if (val < 10) {
                    return `0${val}`
                }
                return val
            });
        }
        //递归每秒调用countTime方法，显示动态时间效果
        setTimeout(countTime, 1000);
    }

    useEffect(() => {


        countTime()
    })

    return (
        <div className="countDownBox">
            <div className="item">
                <div className="num"><span id="_d">{Day} </span></div>
                <div className="text">days</div>
            </div>
            <div className="spec">
                :
            </div>
            <div className="item">
                <div className="num"><span id="_h">{Hour} </span></div>
                <div className="text">hours</div>
            </div>
            <div className="spec">
                :
            </div>
            <div className="item">
                <div className="num"><span id="_m">{Minute} </span></div>
                <div className="text">mins</div>
            </div>
            <div className="spec">
                :
            </div>
            <div className="item">
                <div className="num"><span id="_s">{Second} </span></div>
                <div className="text">secs</div>
            </div>
        </div>
    )
}

export default CountDown;